import React from 'react';
import { Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Title from '../common/Title';

// Import Section Title
import ServiceBox from './services-box';

const Process = ({ sectionClass }) => {
  const services = useSelector((state) => state.site?.sections?.services);
  return (
      <React.Fragment>
        <section className={`section ${sectionClass}`} id="services">
          <Container>
            {/* section title */}
            <Title
              title={services.title}
              desc={services.description}
            />

            <Row className="mt-4">
              {/* services box */}
              <ServiceBox services={services?.list?.services1} />
            </Row>

            <Row className="mt-4">
              {/* service box */}
              <ServiceBox services={services?.list?.services2} />
            </Row>
          </Container>
        </section>
      </React.Fragment>
  );
};

Process.propTypes = {
  sectionClass: PropTypes.string,
};

export default Process;
