import React from 'react';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import {
//   Container, Row, Col, Form,
// } from 'reactstrap';

// Import Footer link
import FooterLinks from './footer-links';

// const links = useSelector((state) => state.site?.footer_links);
// const footer = useSelector((state) => state.site?.footer);
const Footer = () => (
    <React.Fragment>
      {/* <footer className="footer">
        <Container>
          <Row>
            {links && links.map((fLink, key) => (
              <Col key={key} lg={3} className="mt-4">
                <h4>{fLink.title}</h4>
                <div className="text-muted mt-4">
                  <ul className="list-unstyled footer-list">
                    {fLink.child.map((fLinkChild, key2) => (
                      <li key={key2}>
                        <Link to={fLinkChild.link}>{fLinkChild.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}

            <Col lg={3} className="mt-4">
              <h4>{footer?.subscription?.label}</h4>
              <div className="text-muted mt-4">
                <p>
                  {footer?.subscription?.text}
                </p>
              </div>
              <Form className="subscribe">
                <input
                  placeholder="Email"
                  className="form-control"
                  required
                />
                <Link to="#" className="submit">
                  <i className="pe-7s-paper-plane"></i>
                </Link>
              </Form>
            </Col>
          </Row>
        </Container>
      </footer> */}
      {/* Render footer links */}
      <FooterLinks />
    </React.Fragment>
);
export default Footer;
