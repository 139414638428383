import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Section from './section';
import Service from '../../components/Service/service';
import AboutUs from '../../components/AboutUs/AboutUs';
import WebsiteDesc from '../../components/WebsiteDesc/WebsiteDesc';
import Team from '../../components/Team/Team';
import Process from '../../components/Process/Process';
import Contact from '../../components/Contact/Contact';
// import Social from '../../components/Social/Social';
import Footer from '../../components/Footer/footer';

const Home = () => {
  const seo = useSelector((state) => state.site?.seo);
  return (
    <React.Fragment>
        <Helmet>
          <title>{seo.title}</title>
          <meta name="description" content={seo.description} />
          <meta name="keywords" content={seo.description} />
          <meta name="author" content="Evo Tech Development" />
        </Helmet>
        {/* section */}
        <Section/>

        {/* services */}
        <Service sectionClass="pt-5"/>

        {/* about us */}
        <AboutUs/>

        {/* website description */}
        <WebsiteDesc/>

        {/* pricing */}
        {/* <Pricing/> */}

        {/* team */}
        <Team/>

        {/* process */}
        <Process/>

        {/* testimonial */}
        {/* <Testimonials/> */}

        {/* get started */}
        {/* <GetStart/> */}

        {/* blog */}
        {/* <Blog/> */}

        {/* contact */}
        <Contact/>

        {/* social */}
        {/* <Social /> */}

        {/* footer */}
        <Footer/>
    </React.Fragment>
  );
};

export default Home;
