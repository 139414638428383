import React from 'react';

// Importing Modal
import ModalVideo from 'react-modal-video';
import { useDispatch, useSelector } from 'react-redux';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import { sagaActions } from '../../store/constants/sagaActions';

const ModalSection = () => {
  const isOpen = useSelector((state) => state.ui.isModalOpen);
  const dispatch = useDispatch();
  const channel = 'vimeo';
  const videoId = '99025203';

  return (
    <React.Fragment>
        <ModalVideo
          channel={channel}
          isOpen={isOpen}
          videoId={videoId}
          onClose={() => dispatch(sagaActions.MODAL_REQUESTED)} />
    </React.Fragment>
  );
};

export default ModalSection;
