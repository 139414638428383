/* eslint-disable no-param-reassign */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ScrollspyNav extends Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.scrollTargetIds = this.props.scrollTargetIds;
    this.activeNavClass = this.props.activeNavClass;
    this.scrollDuration = Number(this.props.scrollDuration) || 1000;
    this.headerBackground = this.props.headerBackground === 'true';

    if (this.props.router && this.props.router === 'HashRouter') {
      this.homeDefaultLink = '#/';
      this.hashIdentifier = '#/#';
    } else {
      this.homeDefaultLink = '/';
      this.hashIdentifier = '#';
    }
    this.scrollSection.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  easeInOutQuad(currentTime, start, change, duration) {
    // eslint-disable-next-line no-param-reassign
    currentTime /= duration / 2;
    // eslint-disable-next-line no-mixed-operators
    if (currentTime < 1) return change / 2 * currentTime * currentTime + start;
    // eslint-disable-next-line no-plusplus
    currentTime--;
    // eslint-disable-next-line no-mixed-operators
    return -change / 2 * (currentTime * (currentTime - 2) - 1) + start;
  }

  scrollTo(start, to, duration) {
    const change = to - start;
    let currentTime = 0;
    const increment = 10;

    const animateScroll = () => {
      currentTime += increment;
      const val = this.easeInOutQuad(currentTime, start, change, duration);
      window.scrollTo(0, val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  }

  getNavLinkElement(sectionID) {
    return document.querySelector(`a[href='${this.hashIdentifier}${sectionID}']`);
  }

  getNavToSectionID(navHref) {
    return navHref.includes(this.hashIdentifier) ? navHref.replace(this.hashIdentifier, '') : '';
  }

  componentDidMount() {
    if (document.querySelector(`a[href='${this.homeDefaultLink}']`)) {
      document.querySelector(`a[href='${this.homeDefaultLink}']`).addEventListener('click', (event) => {
        event.preventDefault();
        this.scrollTo(window.pageYOffset, 0, this.scrollDuration);
        window.location.hash = '';
      });
    }

    document.querySelector("div[data-nav='list']").querySelectorAll('a').forEach((navLink) => {
      navLink.addEventListener('click', (event) => {
        event.preventDefault();
        const sectionID = this.getNavToSectionID(navLink.getAttribute('href'));

        if (sectionID) {
          const scrollTargetPosition = document.getElementById(sectionID).offsetTop - (this.headerBackground ? document.querySelector("div[data-nav='list']").scrollHeight : 0);
          this.scrollTo(window.pageYOffset, scrollTargetPosition, this.scrollDuration);
        } else {
          this.scrollTo(window.pageYOffset, 0, this.scrollDuration);
        }
      });
    });

    window.addEventListener('scroll', this.scrollSection, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollSection, true);
  }

    scrollSection = () => {
      let scrollSectionOffsetTop;
      this.scrollTargetIds.forEach((sectionID, index) => {
        scrollSectionOffsetTop = document.getElementById(sectionID).offsetTop - (this.headerBackground ? document.querySelector("div[data-nav='list']").scrollHeight : 0);

        if (window.pageYOffset >= scrollSectionOffsetTop
            // eslint-disable-next-line max-len
            && window.pageYOffset < scrollSectionOffsetTop + document.getElementById(sectionID).scrollHeight) {
          this.getNavLinkElement(sectionID).classList.add(this.activeNavClass);
          this.getNavLinkElement(sectionID).parentNode.classList.add(this.activeNavClass);
          this.clearOtherNavLinkActiveStyle(sectionID);
        } else {
          this.getNavLinkElement(sectionID).classList.remove(this.activeNavClass);
          this.getNavLinkElement(sectionID).parentNode.classList.remove(this.activeNavClass);
        }

        if (window.innerHeight + window.pageYOffset >= document.body.scrollHeight
            && index === this.scrollTargetIds.length - 1) {
          this.getNavLinkElement(sectionID).classList.add(this.activeNavClass);
          this.getNavLinkElement(sectionID).parentNode.classList.add(this.activeNavClass);
          this.clearOtherNavLinkActiveStyle(sectionID);
        }
      });
    }

    clearOtherNavLinkActiveStyle(excludeSectionID) {
      // eslint-disable-next-line no-unused-vars
      this.scrollTargetIds.forEach((sectionID, index) => {
        if (sectionID !== excludeSectionID) {
          this.getNavLinkElement(sectionID).classList.remove(this.activeNavClass);
          this.getNavLinkElement(sectionID).parentNode.classList.remove(this.activeNavClass);
        }
      });
    }

    render() {
      return (
            <div data-nav="list" className={this.props.className}>
                {this.props.children}
            </div>
      );
    }
}

ScrollspyNav.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  scrollTargetIds: PropTypes.any,
  activeNavClass: PropTypes.string,
  scrollDuration: PropTypes.any,
  headerBackground: PropTypes.any,
  router: PropTypes.any,
};

export default ScrollspyNav;
