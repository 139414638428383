import React, { useEffect } from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import NavbarPage from './components/Navbar/Navbar_Page';
import routes from './routes';
import { sagaActions } from './store/constants/sagaActions';
import ErrorBoundary from './pages/Errors';

const App = () => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const isLoading = useSelector((state) => state.ui.isLoading);
  const menu = useSelector((state) => state.site.menu);
  const navClass = useSelector((state) => state.ui.navClass);

  useEffect(() => {
    const loadData = async () => {
      if (!executeRecaptcha) return;
      const recaptcha = await executeRecaptcha('home_page');
      dispatch({ type: sagaActions.SITE_FETCH_REQUESTED, payload: { recaptcha } });
    };
    loadData();
  }, [executeRecaptcha]);

  return (
    <React.Fragment>
      {/* Importing Navbar */}
      <ErrorBoundary>
        {!isLoading
          && <React.Fragment>
            <NavbarPage navItems={menu} navClass={navClass} />
            <Router>
              <Switch>
                {routes.map((route, idx) => (
                  <Route path={route.path} component={route.component} key={idx} />
                ))}
              </Switch>
            </Router>
        </React.Fragment>
        }
      </ErrorBoundary>
    </React.Fragment>
  );
};

export default App;
