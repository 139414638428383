import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const WebsiteDesc = () => {
  const data = useSelector((state) => state.site?.sections?.description);
  return (
    <React.Fragment>
      <section className="section section-lg bg-web-desc">
        <div className="bg-overlay"></div>
        <Container>
          <Row>
            <Col lg={12} className="text-center">
              <h2 className="text-white">{data.title}</h2>
              <p className="pt-3 home-desc">
                {data.description}
              </p>
              <Link
                to={data.button.url}
                className="btn btn-white mt-4 waves-effect waves-light mb-5"
              >
                {data.button.text}
              </Link>
            </Col>
          </Row>
        </Container>
        <div className="bg-pattern-effect">
          <img src="assets/images/bg-pattern.png" alt="EvoTechSite" />
        </div>
      </section>
    </React.Fragment>
  );
};

export default WebsiteDesc;
