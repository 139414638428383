import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import { Link } from 'react-router-dom';

// Import Section Title
import { useSelector } from 'react-redux';
import ProcessBox from './ProcessBox';
import Title from '../common/Title';

const Process = () => {
  const processes = useSelector((state) => state.site?.sections?.processes);

  return (
    <React.Fragment>
      <section className="section bg-light">
        <Container>
          {/* section title */}
          <Title
            title={processes?.title}
            desc={processes?.desc}
          />

          <Row>
            <Col lg={6} className="text-center process-left-icon-1">
              <i className="pe-7s-angle-right"></i>
            </Col>
            <Col lg={6} className="text-center process-left-icon-2">
              <i className="pe-7s-angle-right"></i>
            </Col>
          </Row>
          <Row className="mt-5">
            <ProcessBox processes={processes?.list} />
            <div className="text-center mx-auto">
              {/* <Link
                to={processes?.button?.url}
                className="btn btn-primary waves-light waves-effect mt-5"
              >
                {processes?.button?.text} <i className="mdi mdi-arrow-right"></i>
              </Link> */}
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Process;
