/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { sagaActions } from '../constants/sagaActions';

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    site: null,
    phone: null,
    email: null,
    menu: [],
    slider: {},
    sections: {},
    social_icons: [],
    footer_links: [],
    footer: {},
    seo: {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(sagaActions.SITE_FETCH_SUCCEEDED, (state, action) => ({
        ...state,
        site: action.payload.site,
        phone: action.payload.phone,
        email: action.payload.email,
        menu: action.payload.menu,
        slider: action.payload.slider,
        sections: action.payload.sections,
        social_icons: action.payload.social_icons,
        footer_links: action.payload.footer_links,
        footer: action.payload.footer,
        seo: action.payload.seo,
      }))
      .addDefaultCase((state, action) => {});
  },
});

export const siteReducers = siteSlice.reducer;

export default siteSlice.reducer;
