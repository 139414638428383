import React, { useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import {
  Container, Row, Col, FormGroup, Input,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { sagaActions } from '../../store/constants/sagaActions';
import Title from '../common/Title';

const Contact = () => {
  const dispatch = useDispatch();
  const {
    register, handleSubmit, reset, formState: { errors },
  } = useForm();
  const contact = useSelector((state) => state.site?.sections?.contact_us);
  const hasErrorMessage = useSelector((state) => state.ui?.error);
  const hasFormSubmitted = useSelector((state) => state.ui?.formSubmitted);
  const [disabledFields, setDisabledFields] = useState(false);
  const specialBtnStatus = true;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = (data, e) => {
    // eslint-disable-next-line no-debugger
    if (!specialBtnStatus) {
      if (!executeRecaptcha) return;
    }
    setDisabledFields(true);
    setTimeout(async () => {
      const recaptcha = await executeRecaptcha('form_submit');
      dispatch({ type: sagaActions.FORM_SEND_REQUESTED, payload: { ...data, recaptcha } });
    }, 500);
  };

  const hasError = () => {
    const swalCnf = !specialBtnStatus ? {
      title: 'Spam Protection!',
      text: 'If you want to contact us, please use the following email: founders@evotech.bg',
      icon: 'warning',
    } : {
      title: 'Error!',
      text: hasErrorMessage,
      icon: 'error',
    };
    Swal.fire(swalCnf).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setTimeout(() => {
          dispatch({ type: sagaActions.FORM_SEND_RESET });
          if (specialBtnStatus) setDisabledFields(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    if (hasFormSubmitted) {
      Swal.fire({
        title: 'Thank you for your request!',
        text: 'We will contact you soon!',
        icon: 'success',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setTimeout(() => {
            dispatch({ type: sagaActions.FORM_SEND_RESET });
            if (specialBtnStatus) setDisabledFields(false);
            reset();
          }, 5000);
        }
      });
    }
  }, [hasFormSubmitted]);

  return (
    <React.Fragment>
      <section className="section " id="contact">
        <Container>
          {/* section title */}
          <Title
            title={contact?.title}
            desc={contact?.desc}
          />

          <Row>
            <Col lg={4}>
              <div className="mt-4 pt-4">
                {contact?.location
                  && contact.location.map((location, key) => (
                    <p className="mt-4" key={key}>
                      <span className="h5">{location.label}</span>
                      <br />{' '}
                      <span className="text-muted d-block mt-2" dangerouslySetInnerHTML={{ __html: location.value }}/>
                    </p>
                  ))}
              </div>
            </Col>
            <Col lg={8}>
              <div className="custom-form mt-4 pt-4">
              <div id="message"></div>
              <form onSubmit={handleSubmit(onSubmit)} name="contact-form" id="contact-form">
                <Row>
                  <Col lg={6}>
                    <FormGroup className="mt-2">
                    <Input type="text" className={`form-control ${errors.names ? ' is-touched is-pristine av-invalid is-invalid ' : ''}`}
                      readOnly={disabledFields}
                      defaultValue={!specialBtnStatus ? 'Disabled' : ''}
                      placeholder="Your names" {...register('names', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Your name must be between 3 and 32 characters',
                        },
                        maxLength: {
                          value: 32,
                          message: 'Your name must be between 3 and 32 characters',
                        },
                      })} />
                    {errors.names && <div className="invalid-feedback">{errors.names.message}</div>}
                    </FormGroup>
                  </Col>
                  <Col lg={6}>
                    <FormGroup className="mt-2">
                      <Input type="email" className={`form-control ${errors.email ? ' is-touched is-pristine av-invalid is-invalid ' : ''}`}
                        readOnly={disabledFields}
                        defaultValue={!specialBtnStatus ? 'Disabled@func.bg' : ''}
                        placeholder="Your Email" {...register('email', {
                          required: 'This field is required',
                          maxLength: {
                            value: 62,
                            message: 'Your email must be between 3 and 62 characters',
                          },
                          minLength: {
                            value: 3,
                            message: 'Your email must be between 3 and 62 characters',
                          },
                          pattern: {
                            value: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/i,
                            message: 'The fields should content valid email address',
                          },
                        })} />
                      {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mt-2">
                    <Input type="text" className={`form-control ${errors.subject ? ' is-touched is-pristine av-invalid is-invalid ' : ''}`}
                      readOnly={disabledFields}
                      defaultValue={!specialBtnStatus ? 'Disabled' : ''}
                      placeholder="Subject" {...register('subject', {
                        required: 'This field is required',
                        minLength: {
                          value: 3,
                          message: 'Your subject must be between 3 and 64 characters',
                        },
                        maxLength: {
                          value: 64,
                          message: 'Your subject must be between 3 and 64 characters',
                        },
                      })} />
                    {errors.subject && <div className="invalid-feedback">{errors.subject.message}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormGroup className="mt-2">
                      <textarea className={`form-control ${errors.content ? ' is-touched is-pristine av-invalid is-invalid ' : ''}`} {...register('content', {
                        required: 'This field is required',
                        minLength: {
                          value: 10,
                          message: 'Your subject must be between 10 and 500 characters',
                        },
                        maxLength: {
                          value: 500,
                          message: 'Your subject must be between 10 and 500 characters',
                        },
                      })}
                      readOnly={disabledFields}
                      defaultValue={!specialBtnStatus ? 'Disabled Functionality' : ''} />
                      {errors.content && <div className="invalid-feedback">{errors.content.message}</div>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="text-end">
                    { hasErrorMessage && hasError() }
                    <Input
                      type="submit"
                      disabled={disabledFields && specialBtnStatus}
                      id="submit"
                      name="send"
                      className="submitBnt btn btn-primary"
                      value={specialBtnStatus ? 'Send Message' : 'Checkout More'}/>
                    <div id="simple-msg"></div>
                  </Col>
                </Row>
              </form>
              {hasFormSubmitted && (<div className="form-overlay"><div className="message">SUCCESS</div></div>)}
            </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Contact;
