import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';

// Import Section Title
import Title from '../common/Title';
import TeamBox from './TeamBox';

const Clients = () => {
  const data = useSelector((state) => state.site?.sections?.teams);

  return (
    <React.Fragment>
      <section className="section pt-4" id="team">
        <Container>
          {/* section title */}
          <Title
            title={data.title}
            desc={data.description}
          />

          <Row className="mt-5">
            {/* team box */}
            <TeamBox teams={data.list} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Clients;
