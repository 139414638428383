import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store';

const app = (

  <GoogleReCaptchaProvider
    useRecaptchaNet={true}
    reCaptchaKey={'6LfegKgaAAAAAIUS_2CS7YMWJ6g_PbBCLD-89xkd'}
    scriptProps={{ async: true, defer: true, appendTo: 'head' }}
  >
    <Provider store={store}>
      <BrowserRouter>
          <App />
      </BrowserRouter>
    </Provider>
  </GoogleReCaptchaProvider>
);

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  render(app, rootElement);
}
serviceWorker.unregister();
