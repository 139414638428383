import {
  call, put, takeLatest,
} from 'redux-saga/effects';
import { sagaActions } from './constants/sagaActions';
import Api from './services/api';

function* fetchData(req) {
  try {
    const { payload: { recaptcha } } = req;
    const data = yield call(Api.fetchData, recaptcha);
    yield put({ type: sagaActions.SITE_FETCH_SUCCEEDED, payload: data.data });
    yield put({ type: sagaActions.IS_LOADING_SUCCESS, payload: false });
  } catch (e) {
    yield put({ type: sagaActions.SITE_FETCH_FAILED, message: e.message });
  }
}

function* submitForm(req) {
  try {
    yield call(Api.submitForm, req.payload);
    yield put({ type: sagaActions.FORM_SEND_SUCCEEDED });
    yield put({ type: sagaActions.IS_LOADING_SUCCESS, payload: false });
  } catch (e) {
    yield put({ type: sagaActions.FORM_SEND_FAILED, message: e.message });
  }
}

function* openMenu() {
  yield put({ type: sagaActions.MENU_OPEN_SUCCESS });
}

function* changeNavClass(payload) {
  yield put({ type: sagaActions.NAVCLASS_CHANGE_SUCCESS, payload });
}

function* openModal(payload) {
  yield put({ type: sagaActions.MODAL_RESOLVED, payload });
}

function* clearModal() {
  yield put({ type: sagaActions.FORM_SEND_RESET });
}

function* mySaga() {
  yield takeLatest(sagaActions.SITE_FETCH_REQUESTED, fetchData);
  yield takeLatest(sagaActions.FORM_SEND_RESET_REQUEST, clearModal);
  yield takeLatest(sagaActions.FORM_SEND_REQUESTED, submitForm);
  yield takeLatest(sagaActions.MENU_OPEN_REQUESTED, openMenu);
  yield takeLatest(sagaActions.NAVCLASS_CHANGE_REQUESTED, changeNavClass);
  yield takeLatest(sagaActions.MODAL_REQUESTED, openModal);
}

export default mySaga;
