import React from 'react';
import { Container, Row, Col } from 'reactstrap';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AboutUs = () => {
  const data = useSelector((state) => state.site?.sections?.about_us);
  return (
    <React.Fragment>
      <section className="section bg-light" id="features">
        <Container>
          <Row className="vertical-content">
            <Col lg={5}>
              <div className="features-box">
                <div dangerouslySetInnerHTML={{ __html: data.text }}></div>
                {/* <Link
                  to={data.button.url}
                  className="btn btn-primary mt-4 waves-effect waves-light"
                >
                  {data.button.text} <i className="mdi mdi-arrow-right"></i>
                </Link> */}
              </div>
            </Col>
            <Col lg={7}>
              <div className="features-img features-right text-right">
                <img
                  src="assets/images/balloon.png"
                  alt="macbook"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUs;
