import {
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import saga from './sagas';
import { siteReducers } from './features/siteSlice';
import { uiReducers } from './features/uiSlice';

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

const store = configureStore({
  reducer: {
    site: siteReducers,
    ui: uiReducers,
  },
  middleware,
});

sagaMiddleware.run(saga);

export default store;
