import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';

const Title = ({ title, desc }) => (
  <React.Fragment>
    <Row>
      <Col lg={{ size: 8, offset: 2 }}>
        <h1 className="section-title text-center">{title}</h1>
        <div className="section-title-border mt-3"></div>
        <p className="section-subtitle text-muted text-center pt-4 font-secondary">
          {desc}
        </p>
      </Col>
    </Row>
  </React.Fragment>
);

Title.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};

export default Title;
