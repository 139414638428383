export const sagaActions = {
  SITE_FETCH_REQUESTED: 'SITE_FETCH_REQUESTED',
  SITE_FETCH_SUCCEEDED: 'SITE_FETCH_SUCCEEDED',
  SITE_FETCH_FAILED: 'SITE_FETCH_FAILED',
  SITE_FETCH_RESET: 'SITE_FETCH_RESET',
  FORM_SEND_REQUESTED: 'FORM_SEND_REQUESTED',
  FORM_SEND_SUCCEEDED: 'FORM_SEND_SUCCEEDED',
  FORM_SEND_FAILED: 'FORM_SEND_FAILED',
  FORM_SEND_RESET: 'FORM_SEND_RESET',
  FORM_SEND_RESET_REQUEST: 'FORM_SEND_RESET_REQUEST',
  MENU_OPEN_REQUESTED: 'MENU_OPEN_REQUESTED',
  MENU_OPEN_SUCCESS: 'MENU_OPEN_SUCCESS',
  NAVCLASS_CHANGE_REQUESTED: 'NAVCLASS_CHANGE_REQUESTED',
  NAVCLASS_CHANGE_SUCCESS: 'NAVCLASS_CHANGE_SUCCESS',
  MODAL_REQUESTED: 'MODAL_REQUESTED',
  MODAL_RESOLVED: 'MODAL_RESOLVED',
  IS_LOADING_SUCCESS: 'IS_LOADING_SUCCESS',
};

export default {
  sagaActions,
};
