import React from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';

const ProcessBox = ({ processes }) => {
  const len = processes?.length || 0;
  return (
    <React.Fragment>
      {processes && processes.map((process, key) => (
        <Col key={key} lg={4} className={key + 1 === len ? '' : 'plan-line'}>
          <div className="text-center process-box">
            <i className={`${process.icon} text-primary`}></i>
            <h4 className="pt-3" dangerouslySetInnerHTML={{ __html: process.desc }}/>
            {/* <p className="text-muted">{process.title}</p> */}
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

ProcessBox.propTypes = {
  processes: PropTypes.array,
};

export default ProcessBox;
