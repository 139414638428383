/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { sagaActions } from '../constants/sagaActions';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isLoading: true,
    isOpenMenu: false,
    navClass: null,
    isModalOpen: false,
    error: null,
    formSubmitted: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sagaActions.MENU_OPEN_SUCCESS, (state, action) => ({
        ...state,
        isOpenMenu: !state.isOpenMenu,
      }))
      .addCase(sagaActions.NAVCLASS_CHANGE_SUCCESS, (state, action) => ({
        ...state,
        navClass: action.payload,
      }))
      .addCase(sagaActions.MODAL_RESOLVED, (state, action) => ({
        ...state,
        navClass: action.payload,
      }))
      .addCase(sagaActions.IS_LOADING_SUCCESS, (state, action) => ({
        ...state,
        isLoading: action.payload,
      }))
      .addCase(sagaActions.FORM_SEND_FAILED, (state, action) => ({
        ...state,
        error: action.message,
      }))
      .addCase(sagaActions.SITE_FETCH_FAILED, (state, action) => ({
        ...state,
        error: action.message,
      }))
      .addCase(sagaActions.FORM_SEND_RESET, (state) => ({
        ...state,
        error: null,
        formSubmitted: false,
      }))
      .addCase(sagaActions.FORM_SEND_SUCCEEDED, (state) => ({
        ...state,
        formSubmitted: true,
      }))
      .addDefaultCase((state, action) => {});
  },
});

export const uiReducers = uiSlice.reducer;

export default uiSlice.reducer;
