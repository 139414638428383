import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Navbar,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
} from 'reactstrap';
import StickyHeader from 'react-sticky-header';

import ScrollspyNav from './scrollSpy';

// stickey header
import 'react-sticky-header/styles.css';
import { sagaActions } from '../../store/constants/sagaActions';

const NavbarPage = () => {
  const data = useSelector((state) => state.site);
  const navClass = useSelector((state) => state.ui?.navClass);
  const isOpenMenu = useSelector((state) => state.ui?.isOpenMenu);
  const dispatch = useDispatch();

  // Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
  const targetId = data.menu.map((item) => item.idnm);
  return (
    <React.Fragment>
      <StickyHeader
        header={
          <Navbar
            expand="lg"
            fixed="top"
            className={
              `navbar-custom sticky sticky-dark ${navClass}`
            }
          >
            <Container>
              <NavbarBrand className="logo text-uppercase" href="/">
                {data.site}
              </NavbarBrand>
              <NavbarToggler onClick={() => dispatch({ type: sagaActions.MENU_OPEN_REQUESTED })}>
                <i className="mdi mdi-menu"></i>
              </NavbarToggler>

              <Collapse
                id="navbarCollapse"
                isOpen={isOpenMenu}
                navbar
              >
                <ScrollspyNav
                  scrollTargetIds={targetId}
                  scrollDuration="800"
                  headerBackground="true"
                  activeNavClass="active"
                  className="navbar-collapse"
                >
                  <Nav navbar className="navbar-center" id="mySidenav">
                    {data?.menu && data.menu.map((item, key) => (
                      <NavItem
                        key={key}
                        className={item.navheading === 'Home' ? 'active' : ''}
                      >
                        <NavLink href={`#${item.idnm}`}>
                          {' '}
                          {item.navheading}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </ScrollspyNav>
              </Collapse>
            </Container>
          </Navbar>
        }
        stickyOffset={-100}
      ></StickyHeader>
    </React.Fragment>
  );
};

export default NavbarPage;
