import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

const FooterLinks = () => {
  const phone = useSelector((state) => state.site?.phone);
  const email = useSelector((state) => state.site?.email);

  return (
    <React.Fragment>
      <div className="footer-alt">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="float-start pull-none">
                <p className="copy-rights text-muted">
                  {new Date().getFullYear()} © EvoTech Site - Evo Tech
                </p>
              </div>
              <div className="float-end pull-none">
                <p className="copy-rights text-muted font-weight-500">
                  <i className="pe-7s-call"></i> &nbsp; {phone}
                  <i className="pe-7s-mail-open"></i>&nbsp; {email.replace('@', ' (at) ')}
                </p>
              </div>
              <div className="clearfix"></div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FooterLinks;
