import axios from 'axios';

axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api-v1' : 'https://api.evotech.bg/api-v1';
axios.defaults.headers.post['Content-Type'] = 'application/json';

// services/api.js
function fetchData(recaptcha) {
  return axios.get(`/getData?recaptcha=${recaptcha}`);
}

function submitForm(data) {
  return axios.post('/contactUs', data);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  fetchData,
  submitForm,
};
